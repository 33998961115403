import { RABBITMQ } from '@/rabbitMq/common'
import { buildMessage } from '@/rabbitMq/build_message'

export { queueMessage, sendStripeDeclined, sendOrderConfirmationEmail, submitAdToBeProcessed, sendContactUsForm }

/**
 * Queues message on rabbitmq
 * @param resource - Resource name
 * @param action - Resource action
 * @param data - Message data
 * @param postActions - Anything to do after first action
 */
function queueMessage (resource, action, data, postActions) {
  let message = buildMessage(resource, action, data, postActions)
  return RABBITMQ.post('rabbitmq/queue', message)
    .then(response => response.data)
}

/**
 * Send stripe declined message
 * @param onlineOrder - Online order object
 * @param accountName - Store account name
 * @param chargeResponse - Stripe charge response
 */
function sendStripeDeclined (onlineOrder, accountName, chargeResponse, alert=false) {
  queueMessage('email', 'send', {
    users: [],
    template: 'templates/online_sales/declined.ejs',
    subject: `${alert ? 'ALERT: ' : ''}Order by ${onlineOrder.billingFname} ${onlineOrder.billingLname} from ${accountName} declined`,
    data: {
      order: onlineOrder,
      reason: `${chargeResponse.type} | ${chargeResponse.code} | ${chargeResponse.declineCode} | ${chargeResponse.message}`,
      storeName: accountName
    }
  })
}

/**
 * Sends order confirmation email
 * @param onlineOrder - Online order object
 * @param account - Store account opbject
 * @param store - Store object
 */
function sendOrderConfirmationEmail (onlineOrder, account, store) {
  const subject  = (onlineOrder.instructions && onlineOrder.instructions.length > 0)
    ? `Thank you for your order from ${account.name}'s Store - Special Instructions` : `Thank you for your order from ${account.name}'s Store`
  queueMessage('email', 'send', {
    users: [
      {
        firstName: onlineOrder.billingFname,
        lastName: onlineOrder.billingLname,
        email: onlineOrder.billingEmail
      }
    ],
    template: 'templates/online_sales/invoice.ejs',
    subject: subject,
    data: {
      order: onlineOrder,
      useWagner: account.parentId === 'S00017',
      useWilcoxRecognition: account.parentId === 'BYZRIL',
      useCkVisuals: account.parentId === 'M9AOQ4',
      bUsesServiceFee: store.bParentsPayConvenienceFee,
      // SimplePix consolidates fees into just service fee
      amountServiceFee: account.parentId === '87PNV2' ? (Number(onlineOrder.calculatedConvenienceFee) + Number(onlineOrder.dedicationAdFee)).toFixed(2) : Number(onlineOrder.calculatedConvenienceFee).toFixed(2),
      dedicationAdFee: account.parentId === '87PNV2' ? 0 : Number(onlineOrder.dedicationAdFee).toFixed(2)
    }
  })
}

/**
 * Submits ad to be rendered on Pixami
 * @param ad - Ad object
 * @param onlineOrder - Online order object
 * @param account - Store account opbject
 * @param student - Student information object
 */
function submitAdToBeProcessed (ad, onlineOrder, account, student) {
  queueMessage('integration.pixami', 'dedications', {
    ad: ad,
    onlineOrder: onlineOrder,
    account: account,
    student: student
  })
}

/**
 * Sends contact us form
 * @param contact - Contact information
 * @param account - Account information
 */
function sendContactUsForm (contact, account) {
  queueMessage('email', 'send', {
    users: [{ firstName: contact.name, email: contact.email }],
    template: 'templates/online_sales/contact.ejs',
    subject: `Support request for ${account.name}`,
    data: {
      contact: contact,
      storeName: `${account.name}'s Store`,
      useWagner: account.parentId === 'S00017'
    }
  })
}
